.tournament-show {
    &-block {
        overflow: hidden;
        height: 300px;
    }

    &-image {
        height: 100%;
        background: no-repeat center;
        background-size: cover;
        opacity: .6;
    }
}
