body, html, * {
    font-family: $font-montserrat;
}

.content {
    min-height: 500px;
}

.navbar {
    .navbar-brand {
        font-size: 1.3rem;
    }

    .navbar-nav {
        .nav-item {
            &.active {
                border-bottom: 1px solid $blue-pacific;
                border-top: 1px solid $orange-sunset;
            }

            .nav-link {
                font-size: 1.2rem;
            }
        }
    }
}
