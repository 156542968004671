@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import 'mixins/colors';
@import 'mixins/fonts';

@import 'layout/base';
@import 'layout/buttons';

@import 'pages/home';
@import 'pages/tournament';

.delimiter {
    color: $orange-sunset;
    font-size: 60px;
    line-height: 10px;
    margin-bottom: 20px;
}
