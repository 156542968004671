.child {
    background: url('../images/header.jpg') no-repeat center;
    background-size: cover;
    height: 450px;
    position: relative;
}

.child h1 {
    position: absolute;
    right: 4%;
    bottom: 6%;
    font-size: 66px;
    font-weight: bold;
    color: $blue-pacific;
    line-height: 55px;
}

.child h1 > span {
    font-size: 35px;
    font-weight: normal;
    color: $white;
}

.badge-adf {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1%;
    z-index: 10;
    height: 150px;
    width: 150px;
    top: -146px;
    border-radius: 50%;
    text-align: center;
    color: $white;
    font-weight: bold;
    box-shadow: 5px 5px 3px #656565;
}

.jumbotron {
    color: $blumine;
}
