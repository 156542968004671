.btn-adf {
    color: $white;
    background-color: $orange-sunset;
    border-color: $orange-sunset;
}

.btn-adf:hover {
    color: $blue-charcoal;
    background-color: $bright-turquoise;
    border-color: $bright-turquoise;
}
